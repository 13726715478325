Object.defineProperty(navigator, 'userAgent', {get: function () { return 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/130.5.136.2 Safari/537.36' }, configurable: true });

function shuffle(array) {
  var currentIndex = array.length, tempValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    tempValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = tempValue;
  }
  return array;
}
var tempPlugins = [];
if (window.navigator.plugins) {
  if (window.navigator.plugins.length) {
    var navigatorPluginsLength = window.navigator.plugins.length,
    navigatorPlugins = window.navigator.plugins;
    Object.setPrototypeOf(navigatorPlugins, Array.prototype);
    navigatorPlugins.length = navigatorPluginsLength;
    navigatorPlugins.forEach(function(plugin) {
      var tempPlugin = { 
        name: plugin.name,
        description: plugin.description,
        filename: plugin.filename,
       version: plugin.version,
         length: plugin.length,
          item: function(index) { return this[index] || null; },
          namedItem: function(name) { return this[name] || null; }
       };
      var tempPluginsLength = plugin.length;
       Object.setPrototypeOf(plugin, Array.prototype);
       plugin.length = tempPluginsLength;
       plugin.forEach(function(mime, i) {
           tempPlugin[i] = tempPlugin[mime.type] = mime;
        });
      Object.setPrototypeOf(tempPlugin, Plugin.prototype);
      tempPlugins.push(tempPlugin);
    });
  }
}
var injectedPlugins = [{'filename': 'vtaudioplayback', 'name': 'VT AudioPlayback', '0': {'type': 'application/vt-audio', 'suffixes': '', 'description': ''}, 'description': 'VT audio playback'}, {'description': 'Chanw checking plugin', 'filename': 'chanwebplugin', '0': {'description': '', 'type': 'application/chan-web', 'suffixes': ''}, 'name': 'ChanWebPlugin'}];
if (injectedPlugins) {
  injectedPlugins.forEach(function(plugin, v) {
    var tempPlugin = {
      name: plugin.name,
      description: plugin.description,
      filename: plugin.filename,
      version: undefined,
      length: 1,
      item: function(index) { return this[index] || null; },
      namedItem: function(name) { return this[name] || null; }
    };
    var tempMime = {
      description: plugin[0].description,
      suffixes: plugin[0].suffixes,
      type: plugin[0].type,
      enabledPlugin: null
    };
    Object.setPrototypeOf(tempMime, MimeType.prototype);
    tempPlugin[0] = tempPlugin[tempMime.type] = tempMime;
    Object.setPrototypeOf(tempPlugin, Plugin.prototype);
    tempPlugins.push(tempPlugin);
  });
}
var resultPlugins = {
  length: tempPlugins.length,
  item: function(index) { return this[index] || null; },
  namedItem: function(name) { return this[name] || null; },
  refresh: function() {}
};
tempPlugins = shuffle(tempPlugins);
tempPlugins.forEach(function(plugin, i) {
  resultPlugins[i] = resultPlugins[plugin.name] = plugin;
});
Object.setPrototypeOf(resultPlugins, PluginArray.prototype);
Object.defineProperty(window.navigator, 'plugins', {
  get: function() {
      return resultPlugins;
  },
  enumerable: true,
  configurable: true
});

// Function to toggle the chat widget visibility
function toggleChat() {
  const chatWidgetContainer = document.getElementById("chatWidgetContainer");

  // Toggle display between 'none' and 'block'
  if (chatWidgetContainer.style.display === "none" || chatWidgetContainer.style.display === "") {
    chatWidgetContainer.style.display = "block";
  } else {
    chatWidgetContainer.style.display = "none";
  }
}

window.toggleChat = toggleChat;